/* ==========================================================================
   About Section
   ========================================================================== */
.about-bg img {
  width: 100%;
}
.about-bg {
  position: relative;
}
.about-bg .dots {
  background-image: url(../../img/doted-pattern.png);
  position: absolute;
  width: 300px;
  height: 300px;
  left: -200px;
  bottom: 20px;
  opacity: 0.7;
}
.profile-info {
  margin-bottom: 30px;
}
.profile-info li {
  line-height: 35px;
}
.profile-info li span {
  font-weight: 600;
  width: 100px;
  letter-spacing: 0;
  color: #9cbc1b;
  position: relative;
  margin-right: 10px;
}
.profile-info li span:after {
  content: ":";
  position: absolute;
  right: 0;
  top: 0;
}
