/* =======  Google Fonts  ======= */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Teko:400,500,600,700&display=swap");

/* ==========================================================================
   Global Properties
   ========================================================================== */

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  list-style: none;
  word-wrap: break-word;
}
body {
  font-family: "Open Sans", sans-serif !important;
  font-size: 15px !important;
  line-height: 26px !important;
  font-weight: 400 !important;
  color: #999 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #292929 !important;
}
p {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #999;
  margin-bottom: 20px;
}
img {
  width: 100%;
  height: auto;
}
span,
a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

span:hover,
a:hover {
  text-decoration: none !important;
  color: #9cbc1b !important;
}
b {
  color: #9cbc1b;
  font-weight: 400;
}
h1 {
  font-size: 60px !important;
}
h2 {
  font-size: 50px !important;
}
h3 {
  font-size: 40px !important;
}
h4 {
  font-size: 30px !important;
}
h5 {
  font-size: 20px !important;
}
h6 {
  font-size: 15px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  font-weight: 400 !important;
  font-family: "Teko", sans-serif !important;
}

/* =======  Margin  ======= */
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}

/* ======= Padding Styles  ======= */
.section-padding {
  padding: 100px 0;
}
.padding-10 {
  padding: 10px;
}
.padding-15 {
  padding: 15px;
}

/* =======  Border Style  ======= */
.bd-bottom {
  border-bottom: 1px solid #333;
}
.bd-top {
  border-top: 1px solid #333;
}

/* =======  Scrollbar  ======= */
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #292929;
}
::-webkit-scrollbar-thumb:hover {
  background: #292929;
}

/* selection */
::-webkit-selection {
  color: #999;
  background: rgba(0, 0, 0, 0.1);
}
::-moz-selection {
  color: #999;
  background: rgba(0, 0, 0, 0.1);
}
::selection {
  color: #999;
  background: rgba(0, 0, 0, 0.1);
}

/* =======  Preloader Styles  ======= */
.loaded .site-preloader-wrap {
  opacity: 0;
  visibility: hidden;
}
.site-preloader-wrap {
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
}
.site-preloader-wrap .spinner {
  background-color: #cba982;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
}
.spinner {
  width: 40px;
  height: 40px;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

/* =======  Lines Styles  ======= */
.dl-side-content {
  position: relative;
}
.content-lines-wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.content-lines-inner {
  position: absolute;
  width: 1140px;
  left: 50%;
  margin-left: -570px;
  top: 0;
  height: 100%;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
}
.content-lines {
  position: absolute;
  left: 33.666666%;
  right: 33.666666%;
  height: 100%;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
}

/* ======= Media Query  ======= */
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
@media screen and (max-width: 1199px) {
  .mobile-navigation {
    display: inline-block;
  }
  #primary-navigation {
    display: none;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .sm-padding {
    padding: 15px 0;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .about-bg .dots {
    display: none;
  }
  .header {
    background-attachment: scroll !important;
    background-position: 50% 0 !important;
  }
  .header .caption h4 {
    font-size: 30px;
  }
  .header .caption h1 {
    font-size: 45px;
    line-height: 1.2em;
  }
  .services .feat-item.active .text-center {
    padding-top: 50px;
    margin-top: 0;
  }
  .about .tab-content .image {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    margin: 50px 0 0;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .row {
    margin: 0;
  }
  .xs-padding {
    padding: 15px;
  }
  .section-padding {
    padding: 60px 0;
  }
  .dl-side-content > .logo img {
    max-width: inherit;
    max-height: 30px;
    width: auto;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
  .hero-social {
    position: inherit;
    left: inherit;
    top: inherit;
    transform: translateY(0);
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }
  .hero-social li {
    display: inline-block;
    margin: 0 10px;
  }
  .contact-form .form-group {
    margin-bottom: 15px;
  }
  .cta-section,
  .cta-section .text-right {
    text-align: center !important;
  }
}
@media screen and (max-width: 580px) {
  footer {
    text-align: center;
  }
  .footer-social {
    text-align: center !important;
    margin-top: 14px;
  }
  .footer-social a {
    margin: 0 10px;
  }
}
@media screen and (max-width: 480px) {
  .header .caption h4 {
    font-size: 15px;
    line-height: 1.75em;
  }
  .header .caption h1 {
    font-size: 40px;
    line-height: 1.2em;
  }

  .dl-side-content > .logo img {
    max-width: inherit;
    max-height: 30px;
    width: auto;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
  .header .caption p {
    font-size: 15px;
    font-weight: 400;
    color: #eee;
    word-spacing: 2px;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
}

/* =======  Buttons Styles  ======= */
.default-btn {
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.5px;
  background-color: #9cbc1b;
  color: #000;
  line-height: 45px;
  display: inline-block;
  padding: 0 35px;
  border-radius: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.default-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #333;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  -webkit-transform: scale(0, 1);
  -moz-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  -o-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: -ms-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}
.default-btn:hover {
  color: #fff;
}
.default-btn:hover:before {
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.default-btn i {
  margin-left: 10px;
}
