@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Teko:400,500,600,700&display=swap);
/* =======  Google Fonts  ======= */

/* ==========================================================================
   Global Properties
   ========================================================================== */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  list-style: none;
  word-wrap: break-word;
}
body {
  font-family: "Open Sans", sans-serif !important;
  font-size: 15px !important;
  line-height: 26px !important;
  font-weight: 400 !important;
  color: #999 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #292929 !important;
}
p {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #999;
  margin-bottom: 20px;
}
img {
  width: 100%;
  height: auto;
}
span,
a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

span:hover,
a:hover {
  text-decoration: none !important;
  color: #9cbc1b !important;
}
b {
  color: #9cbc1b;
  font-weight: 400;
}
h1 {
  font-size: 60px !important;
}
h2 {
  font-size: 50px !important;
}
h3 {
  font-size: 40px !important;
}
h4 {
  font-size: 30px !important;
}
h5 {
  font-size: 20px !important;
}
h6 {
  font-size: 15px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  font-weight: 400 !important;
  font-family: "Teko", sans-serif !important;
}

/* =======  Margin  ======= */
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}

/* ======= Padding Styles  ======= */
.section-padding {
  padding: 100px 0;
}
.padding-10 {
  padding: 10px;
}
.padding-15 {
  padding: 15px;
}

/* =======  Border Style  ======= */
.bd-bottom {
  border-bottom: 1px solid #333;
}
.bd-top {
  border-top: 1px solid #333;
}

/* =======  Scrollbar  ======= */
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #292929;
}
::-webkit-scrollbar-thumb:hover {
  background: #292929;
}

/* selection */
::-webkit-selection {
  color: #999;
  background: rgba(0, 0, 0, 0.1);
}
::selection {
  color: #999;
  background: rgba(0, 0, 0, 0.1);
}

/* =======  Preloader Styles  ======= */
.loaded .site-preloader-wrap {
  opacity: 0;
  visibility: hidden;
}
.site-preloader-wrap {
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
}
.site-preloader-wrap .spinner {
  background-color: #cba982;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
}
.spinner {
  width: 40px;
  height: 40px;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

/* =======  Lines Styles  ======= */
.dl-side-content {
  position: relative;
}
.content-lines-wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.content-lines-inner {
  position: absolute;
  width: 1140px;
  left: 50%;
  margin-left: -570px;
  top: 0;
  height: 100%;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
}
.content-lines {
  position: absolute;
  left: 33.666666%;
  right: 33.666666%;
  height: 100%;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
}

/* ======= Media Query  ======= */
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
@media screen and (max-width: 1199px) {
  .mobile-navigation {
    display: inline-block;
  }
  #primary-navigation {
    display: none;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .sm-padding {
    padding: 15px 0;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .about-bg .dots {
    display: none;
  }
  .header {
    background-attachment: scroll !important;
    background-position: 50% 0 !important;
  }
  .header .caption h4 {
    font-size: 30px;
  }
  .header .caption h1 {
    font-size: 45px;
    line-height: 1.2em;
  }
  .services .feat-item.active .text-center {
    padding-top: 50px;
    margin-top: 0;
  }
  .about .tab-content .image {
    flex: 100% 1;
    max-width: 100%;
    margin: 50px 0 0;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .row {
    margin: 0;
  }
  .xs-padding {
    padding: 15px;
  }
  .section-padding {
    padding: 60px 0;
  }
  .dl-side-content > .logo img {
    max-width: inherit;
    max-height: 30px;
    width: auto;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
  .hero-social {
    position: inherit;
    left: inherit;
    top: inherit;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }
  .hero-social li {
    display: inline-block;
    margin: 0 10px;
  }
  .contact-form .form-group {
    margin-bottom: 15px;
  }
  .cta-section,
  .cta-section .text-right {
    text-align: center !important;
  }
}
@media screen and (max-width: 580px) {
  footer {
    text-align: center;
  }
  .footer-social {
    text-align: center !important;
    margin-top: 14px;
  }
  .footer-social a {
    margin: 0 10px;
  }
}
@media screen and (max-width: 480px) {
  .header .caption h4 {
    font-size: 15px;
    line-height: 1.75em;
  }
  .header .caption h1 {
    font-size: 40px;
    line-height: 1.2em;
  }

  .dl-side-content > .logo img {
    max-width: inherit;
    max-height: 30px;
    width: auto;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
  .header .caption p {
    font-size: 15px;
    font-weight: 400;
    color: #eee;
    word-spacing: 2px;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
}

/* =======  Buttons Styles  ======= */
.default-btn {
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.5px;
  background-color: #9cbc1b;
  color: #000;
  line-height: 45px;
  display: inline-block;
  padding: 0 35px;
  border-radius: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.default-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #333;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}
.default-btn:hover {
  color: #fff;
}
.default-btn:hover:before {
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.default-btn i {
  margin-left: 10px;
}

/* Bootstrap Menu Custom */

.dl-header-container {
  position: relative;
  text-align: center;
}

.dl-burger-menu {
  position: absolute;
  top: 35px;
  right: 14px;
  width: 20px;
  height: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: -webkit-transform 330ms ease-out;
  transition: transform 330ms ease-out;
  transition: transform 330ms ease-out, -webkit-transform 330ms ease-out;
}

.dl-burger-menu.dl-menu-open {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.dl-line-menu {
  background-color: #292929;
  border-radius: 2px;
  width: 100%;
  height: 2px;
}

.dl-line-menu.dl-line-half {
  width: 50%;
}

.dl-line-menu.dl-first-line {
  transition: -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57), -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  -webkit-transform-origin: right;
          transform-origin: right;
}

.dl-menu-open .dl-line-menu.dl-first-line {
  -webkit-transform: rotate(-90deg) translateX(3px);
  transform: rotate(-90deg) translateX(3px);
}

.dl-line-menu.dl-last-line {
  align-self: flex-end;
  transition: -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57), -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  -webkit-transform-origin: left;
          transform-origin: left;
}

.dl-menu-open .dl-line-menu.dl-last-line {
  -webkit-transform: rotate(-90deg) translateX(-3px);
  transform: rotate(-90deg) translateX(-3px);
}

/* Menu overlay */
.dl-header-overlay .dl-burger-menu,
.dl-fixed-sidebar + .dl-burger-menu {
  display: block;
  position: fixed;
  flex-direction: inherit;
  justify-content: inherit;
  width: 50px;
  height: 47px;
  top: 30px;
  right: 15px;
  background: #9cbc1b;
  padding: 15px;
  z-index: 99999;
  transition: all 0.2s ease-in-out;
}

.dl-header-overlay .dl-burger-menu:hover {
  box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.4);
}

.dl-header-overlay .dl-burger-menu > span,
.dl-fixed-sidebar + .dl-burger-menu > span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 17px;
  transition: -webkit-transform 330ms ease-out;
  transition: transform 330ms ease-out;
  transition: transform 330ms ease-out, -webkit-transform 330ms ease-out;
}

.dl-header-overlay .dl-line-menu,
.dl-fixed-sidebar + .dl-burger-menu .dl-line-menu {
  background-color: #fff;
}

.dl-header-overlay .dl-burger-menu.dl-menu-open,
.dl-fixed-sidebar + .dl-burger-menu.dl-menu-open {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.dl-header-overlay .dl-menu-open > span,
.dl-fixed-sidebar + .dl-menu-open > span {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@-webkit-keyframes menuFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes menuFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Fixed sidebar menu */

.body-fixed-sidebar {
  overflow-x: hidden;
}

.dl-fixed-sidebar {
  position: fixed;
  top: 0;
  width: 300px;
  height: 100%;
  z-index: 9999999;
  overflow-y: auto;
  background-color: #292929;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
}

.dl-sidebar-left {
  left: 0;
  /*    border-right: 1px solid #292929;*/
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.dl-sidebar-left.dl-fixed-sidebar-open {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.dl-fixed-sidebar .dl-header-container {
  /*
    display: flex;
    flex-wrap: wrap;
*/
  height: 100%;
  padding: 0 30px;
}

.dl-fixed-sidebar + .dl-burger-menu {
  z-index: 9999999;
}

.dl-sidebar-left + .dl-burger-menu {
  top: 0;
  right: inherit;
  left: 0;
}

.dl-fixedsidebar-bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
}
.dl-side-content {
  transition: all 0.4s ease-in-out;
}
.dl-fixed-sidebar .logo {
  display: none;
  text-align: center;
  padding: 35px 0 35px 0px;
}
.dl-side-content > .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 47px;
  padding: 0 50px;
  z-index: 99999;
  text-align: center;
  background-color: #292929;
  -o-box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.15);
}
.dl-side-content > .logo img {
  max-width: inherit;
  max-height: 36px;
  width: auto;
}
.dl-fixed-sidebar .logo h1 {
  font-weight: 400;
  font-size: 75px;
  color: #9cbc1b;
}
.dl-fixed-sidebar .logo a span {
  display: block;
  margin-top: 0px;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Teko", sans-serif;
  color: #999;
  font-weight: 400;
  letter-spacing: 10px;
}
.dl-footer-logo {
  padding: 0 0 30px 0;
}
.dl-footer-logo img {
  max-width: inherit;
  max-height: 55px;
  width: auto;
}
.dl-fixed-sidebar-open ~ .dl-fixedsidebar-bg-overlay {
  visibility: visible;
  opacity: 1;
}

.dl-fixed-menu-wrap {
  width: 100%;
}

/* Navigation menu */

.dl-menu-fixed {
  padding-top: 90px;
  text-align: left;
}

.dl-menu-fixed ul li {
  position: relative;
}

.dl-menu-fixed ul li a {
  font-family: "Teko", sans-serif;
  display: block;
  padding-right: 17px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.dl-menu-fixed > ul > li > a.active,
.dl-menu-fixed ul li:hover > a {
  color: #9cbc1b !important;
}

.dl-menu-fixed > ul {
  margin-bottom: 40px;
}

.dl-menu-fixed > ul > li {
  border-bottom: 1px solid #323232;
  margin: 20px 0 20px 0;
  padding: 0 0 10px 0;
}

.dl-menu-fixed > ul > li:last-child {
  margin-bottom: 0;
}

.dl-menu-fixed > ul > li > a {
  font-family: "Teko", sans-serif;
  font-weight: 400;
  color: #999;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.dl-menu-fixed > ul ul {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.dl-menu-fixed > ul ul.submenu-collapse {
  opacity: 1;
}

.dl-menu-fixed > ul ul li {
  margin-bottom: 6px;
}

.dl-menu-fixed > ul ul li:last-child {
  margin-bottom: 0;
}

.dl-menu-fixed > ul ul a {
  position: relative;
  padding-left: 18px;
  font-size: 15px;
  font-style: italic;
  color: #818181;
}

.dl-menu-fixed > ul ul a::before {
  position: absolute;
  content: "";
  top: 3px;
  left: 4px;
  width: 1px;
  height: 12px;
  background-color: #d1d1d1;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.dl-menu-fixed > ul ul a:hover {
  padding-left: 24px;
}

.dl-menu-fixed > ul > li > ul {
  padding: 27px 0 15px;
}

.dl-menu-fixed > ul > li > ul ul {
  padding-top: 6px;
}

.dl-menu-fixed > ul > li > ul > li ul {
  padding-left: 18px;
}

.dl-menu-fixed .overlay-dropdown-plus {
  top: -1px;
  right: -7px;
  opacity: 1;
}

.dl-menu-fixed > ul ul .overlay-dropdown-plus {
  top: -3px;
}

.dl-menu-fixed.submenu-no-separate > ul ul a::before {
  display: none !important;
}

.dl-menu-fixed.submenu-no-separate > ul ul a,
.dl-menu-fixed.submenu-no-separate > ul > li > ul > li ul {
  padding-left: 12px;
}

.dl-menu-fixed.submenu-no-separate > ul ul a:hover {
  padding-left: 18px;
}

/* Menu icons */

.dl-menu-fixed .dl-menu-icon {
  margin-right: 5px;
}

/* Media queries for responsive design */

@media (min-width: 768px) {
  .dl-header .logo {
    float: left;
    padding: 30px 40px 30px 0;
  }

  .dl-menu {
    clear: both;
  }

  .dl-header-shadow-wrapper:after {
    -o-box-shadow: 0px 10px 40px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 40px -5px rgba(0, 0, 0, 0.1);
  }

  .dl-header-overlay .logo {
    left: 30px;
  }

  .dl-header-overlay .dl-burger-menu {
    right: 30px;
  }
}

@media (min-width: 1200px) {
  /* Viewport Breakpoint */
  .dl-header-container {
    height: 90px;
  }

  .dl-header-overlay .dl-header-container {
    height: auto;
  }

  .dl-header .logo {
    position: relative;
    padding: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .dl-header .logo img {
    max-width: inherit;
    max-height: 90px;
    width: auto;
  }

  .dl-burger-menu {
    display: none;
  }

  /* Header sticky */
  .dl-header.sticky {
    position: fixed;
    width: 100%;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: stickyTransition;
    animation-name: stickyTransition;
  }

  .dl-header.sticky .dl-header-container {
    height: 70px;
  }

  /* Center menu */
  .dl-header.center-menu-1 .dl-header-container > .logo {
    display: none;
  }

  .dl-header.center-menu-2 .dl-header-container {
    height: auto;
  }

  /* Menu overlay */
  .dl-header-overlay .dl-burger-menu {
    top: 40px;
    right: 40px;
  }

  /* Fixed sidebar menu */
  .dl-fixed-sidebar {
    transition-duration: 0s;
  }

  .dl-side-content > .logo,
  .dl-fixed-sidebar + .dl-burger-menu,
  .dl-fixedsidebar-bg-overlay {
    display: none;
  }

  .dl-fixed-sidebar .logo {
    display: block;
  }

  .dl-fixed-sidebar .logo img {
    max-width: inherit;
    max-height: 50px;
    width: auto;
    margin: 0 20px;
  }

  .dl-side-content {
    width: calc(100% - 300px);
    transition-duration: 0s;
    -webkit-transform: translateX(0%) !important;
    transform: translateX(0%) !important;
  }

  .dl-sidebar-left ~ .dl-fixedsidebar-bg-overlay + .dl-side-content {
    margin-left: auto;
  }

  .dl-sidebar-left,
  .dl-sidebar-right {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    z-index: auto;
  }

  .dl-menu-fixed {
    padding-top: 0;
    padding-bottom: 30px;
  }
}

/* ==========================================================================
   Hero Section
   ========================================================================== */
.hero-section {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 400px;
  position: relative;
  z-index: 1;
}
.hero-section .particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.hero-content{
  z-index: 99;
}
.hero-content h1 {
  text-transform: uppercase;
}
.hero-content h2 {
  font-size: 24px;
}
.hero-content h4 span {
  color: #9cbc1b;
}
.hero-social {
  position: absolute;
  width: auto;
  height: auto;
  right: 80px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0;
}
.hero-social li {
  line-height: 35px;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 991px) {
  .text-center {
    padding-top: 50 px;
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .hero-social {
    position: inherit;
    left: inherit;
    top: inherit;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }
  .hero-social li {
    display: inline-block;
    margin: 0 10px;
  }
}

/* ==========================================================================
   About Section
   ========================================================================== */
.about-bg img {
  width: 100%;
}
.about-bg {
  position: relative;
}
.about-bg .dots {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAEsCAYAAAB5fY51AAAACXBIWXMAACmGAAAphgEi7N+MAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAARqSURBVHja7N0xyt4wFERRK3h10X6zvUkTcO0BP9Cfc0CQxh5IcbGK5FtJLoAT/PJXAAgWgGABggUgWACCBQgWgGABCBYgWACCBSBYwI92l8+t67p+//vzn+u6pv4FtV27dv/n3STN2Xns8h127dq1++q4EgLHWOX/h+VT1q5du+O7y3/gB5zClRAQLADBAgQLQLAABAsQLADBAhAsQLAABAtAsADBAhAsAMECBAtAsAAECxAsAMECECxAsAAEC0CwAMEC+NxdPucntu3atTu/m6Q5O49dvsOuXbt2Xx1XQuAYK6m+Bn3K2rVrd3y3DRbAOFdCQLAABAsQLADBAhAsQLAABAtAsADBAhAsAMECBAtAsAAECxAsAMECECxAsAAEC0CwAMECECwAwQIEC+Bzd/mcn9i2a9fu/G6S5uw8dvkOu3bt2n11XAmBY6yk+hr0KWvXrt3x3TZYAONcCQHBAhAsQLAABAtAsADBAhAsAMECBAtAsAAECxAsAMECECxAsAAEC0CwAMECECwAwQIEC0CwAAQLECyAz93lc35i265du/O7SZqz89jlO+zatWv31XElBI6xkupr0KesXbt2x3fbYAGMcyUEBAtAsADBAhAsAMECBAtAsAAECxAsAMECECxAsAAEC0CwAMECECwAwQIEC0CwAAQLECwAwQIQLECwAD53l8/5iW27du3O7yZpzs5jl++wa9eu3VfHlRA4xkqqr0Gfsnbt2h3fbYMFMM6VEBAsAMECBAtAsAAECxAsAMECECxAsAAEC0CwAMECECwAwQIEC0CwAAQLECwAwQIQLECwAAQLQLAAwQL43F0+5ye27dq1O7+bpDk7j12+w65du3ZfHVdC4Bgrqb4GfcratWt3fLcNFsA4V0JAsAAECxAsAMECECxAsAAEC0CwAMECECwAwQIEC0CwAAQLECwAwQIQLECwAAQLQLAAwQIQLADBAgQL4HN3+Zyf2LZr1+78bpLm7Dx2+Q67du3afXVcCYFjrKT6GvQpa9eu3fHdNlgA41wJAcECECxAsAAEC0CwAMECECwAwQIEC0CwAAQLECwAwQIQLECwAAQLQLAAwQIQLADBAgQLQLAABAsQLIDP3eVzfmLbrl2787tJmrPz2OU77Nq1a/fVcSUEjrGS6mvQp6xdu3bHd9tgAYxzJQQEC0CwAMECECwAwQIEC0CwAAQLECwAwQIQLECwAAQLQLAAwQIQLADBAgQLQLAABAsQLADBAhAsQLAAPneXz/mJbbt27c7vJmnOzmOX77Br167dV8eVEDjGSqqvQZ+ydu3aHd9tgwUwzpUQECwAwQIEC0CwAAQLECwAwQIQLECwAAQLQLAAwQIQLADBAgQLQLAABAsQLADBAhAsQLAABAtAsADBAvjcXT7nJ7bt2rU7v5ukOTuPXb7Drl27dl8dV0LgGCupvgZ9ytq1a3d8tw0WwDhXQkCwAAQLECwAwQIQLECwAAQLQLAAwQIQLADBAn62vwAAAP//AwBSVNnYyjGLEgAAAABJRU5ErkJggg==);
  position: absolute;
  width: 300px;
  height: 300px;
  left: -200px;
  bottom: 20px;
  opacity: 0.7;
}
.profile-info {
  margin-bottom: 30px;
}
.profile-info li {
  line-height: 35px;
}
.profile-info li span {
  font-weight: 600;
  width: 100px;
  letter-spacing: 0;
  color: #9cbc1b;
  position: relative;
  margin-right: 10px;
}
.profile-info li span:after {
  content: ":";
  position: absolute;
  right: 0;
  top: 0;
}

/* ==========================================================================
   Resume Section
   ========================================================================== */
.exp-list li {
  margin-bottom: 40px;
}
.exp-list li:last-child {
  margin-bottom: 0;
}
.exp-date {
  margin-bottom: 10px;
}
.exp-date i {
  background-color: #9cbc1b;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
}

/* =======  Progress Bar Styles  ======= */
.skill-items .skill-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}
.skill-items .skill-item:last-child {
  margin-bottom: 0;
}
.skill-items .skill-item .progress {
  height: 10px;
  background-color: #ddd;
  box-shadow: none;
  margin-bottom: 0;
  overflow: visible;
  z-index: -1;
}
.skill-items .skill-item .progress,
.skill-items .skill-item .progress .progress-bar {
  border-radius: 2px;
}
.skill-items .skill-item .progress .progress-bar {
  line-height: 5px;
  background-color: #9cbc1b;
  position: relative;
  overflow: visible;
}
.skill-items .skill-item .progress .progress-bar span {
  background-color: #9cbc1b;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-family: "Teko", sans-serif;
  position: absolute;
  top: -10px;
  right: -15px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  z-index: 1;
}
.skill-items .skill-item .progress .progress-bar span:before {
  background-color: rgba(156, 188, 27, 0.8);
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  left: 0;
  top: 0;
  line-height: 30px;
  border-radius: 50%;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  text-align: center;
  z-index: -1;
}

@font-face {
  font-family: "et-line";
  src: url(/static/media/et-line.26ec3c7d.eot);
  src: url(/static/media/et-line.26ec3c7d.eot?#iefix) format("embedded-opentype"),
    url(/static/media/et-line.b01ff252.woff) format("woff"),
    url(/static/media/et-line.98126e3e.ttf) format("truetype"),
    url(/static/media/et-line.569bd908.svg#et-line) format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: "et-line";
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-"] {
*/
.icon-mobile,
.icon-laptop,
.icon-desktop,
.icon-tablet,
.icon-phone,
.icon-document,
.icon-documents,
.icon-search,
.icon-clipboard,
.icon-newspaper,
.icon-notebook,
.icon-book-open,
.icon-browser,
.icon-calendar,
.icon-presentation,
.icon-picture,
.icon-pictures,
.icon-video,
.icon-camera,
.icon-printer,
.icon-toolbox,
.icon-briefcase,
.icon-wallet,
.icon-gift,
.icon-bargraph,
.icon-grid,
.icon-expand,
.icon-focus,
.icon-edit,
.icon-adjustments,
.icon-ribbon,
.icon-hourglass,
.icon-lock,
.icon-megaphone,
.icon-shield,
.icon-trophy,
.icon-flag,
.icon-map,
.icon-puzzle,
.icon-basket,
.icon-envelope,
.icon-streetsign,
.icon-telescope,
.icon-gears,
.icon-key,
.icon-paperclip,
.icon-attachment,
.icon-pricetags,
.icon-lightbulb,
.icon-layers,
.icon-pencil,
.icon-tools,
.icon-tools-2,
.icon-scissors,
.icon-paintbrush,
.icon-magnifying-glass,
.icon-circle-compass,
.icon-linegraph,
.icon-mic,
.icon-strategy,
.icon-beaker,
.icon-caution,
.icon-recycle,
.icon-anchor,
.icon-profile-male,
.icon-profile-female,
.icon-bike,
.icon-wine,
.icon-hotairballoon,
.icon-globe,
.icon-genius,
.icon-map-pin,
.icon-dial,
.icon-chat,
.icon-heart,
.icon-cloud,
.icon-upload,
.icon-download,
.icon-target,
.icon-hazardous,
.icon-piechart,
.icon-speedometer,
.icon-global,
.icon-compass,
.icon-lifesaver,
.icon-clock,
.icon-aperture,
.icon-quote,
.icon-scope,
.icon-alarmclock,
.icon-refresh,
.icon-happy,
.icon-sad,
.icon-facebook,
.icon-twitter,
.icon-googleplus,
.icon-rss,
.icon-tumblr,
.icon-linkedin,
.icon-dribbble {
  font-family: "et-line";
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}
.icon-mobile:before {
  content: "\e000";
}
.icon-laptop:before {
  content: "\e001";
}
.icon-desktop:before {
  content: "\e002";
}
.icon-tablet:before {
  content: "\e003";
}
.icon-phone:before {
  content: "\e004";
}
.icon-document:before {
  content: "\e005";
}
.icon-documents:before {
  content: "\e006";
}
.icon-search:before {
  content: "\e007";
}
.icon-clipboard:before {
  content: "\e008";
}
.icon-newspaper:before {
  content: "\e009";
}
.icon-notebook:before {
  content: "\e00a";
}
.icon-book-open:before {
  content: "\e00b";
}
.icon-browser:before {
  content: "\e00c";
}
.icon-calendar:before {
  content: "\e00d";
}
.icon-presentation:before {
  content: "\e00e";
}
.icon-picture:before {
  content: "\e00f";
}
.icon-pictures:before {
  content: "\e010";
}
.icon-video:before {
  content: "\e011";
}
.icon-camera:before {
  content: "\e012";
}
.icon-printer:before {
  content: "\e013";
}
.icon-toolbox:before {
  content: "\e014";
}
.icon-briefcase:before {
  content: "\e015";
}
.icon-wallet:before {
  content: "\e016";
}
.icon-gift:before {
  content: "\e017";
}
.icon-bargraph:before {
  content: "\e018";
}
.icon-grid:before {
  content: "\e019";
}
.icon-expand:before {
  content: "\e01a";
}
.icon-focus:before {
  content: "\e01b";
}
.icon-edit:before {
  content: "\e01c";
}
.icon-adjustments:before {
  content: "\e01d";
}
.icon-ribbon:before {
  content: "\e01e";
}
.icon-hourglass:before {
  content: "\e01f";
}
.icon-lock:before {
  content: "\e020";
}
.icon-megaphone:before {
  content: "\e021";
}
.icon-shield:before {
  content: "\e022";
}
.icon-trophy:before {
  content: "\e023";
}
.icon-flag:before {
  content: "\e024";
}
.icon-map:before {
  content: "\e025";
}
.icon-puzzle:before {
  content: "\e026";
}
.icon-basket:before {
  content: "\e027";
}
.icon-envelope:before {
  content: "\e028";
}
.icon-streetsign:before {
  content: "\e029";
}
.icon-telescope:before {
  content: "\e02a";
}
.icon-gears:before {
  content: "\e02b";
}
.icon-key:before {
  content: "\e02c";
}
.icon-paperclip:before {
  content: "\e02d";
}
.icon-attachment:before {
  content: "\e02e";
}
.icon-pricetags:before {
  content: "\e02f";
}
.icon-lightbulb:before {
  content: "\e030";
}
.icon-layers:before {
  content: "\e031";
}
.icon-pencil:before {
  content: "\e032";
}
.icon-tools:before {
  content: "\e033";
}
.icon-tools-2:before {
  content: "\e034";
}
.icon-scissors:before {
  content: "\e035";
}
.icon-paintbrush:before {
  content: "\e036";
}
.icon-magnifying-glass:before {
  content: "\e037";
}
.icon-circle-compass:before {
  content: "\e038";
}
.icon-linegraph:before {
  content: "\e039";
}
.icon-mic:before {
  content: "\e03a";
}
.icon-strategy:before {
  content: "\e03b";
}
.icon-beaker:before {
  content: "\e03c";
}
.icon-caution:before {
  content: "\e03d";
}
.icon-recycle:before {
  content: "\e03e";
}
.icon-anchor:before {
  content: "\e03f";
}
.icon-profile-male:before {
  content: "\e040";
}
.icon-profile-female:before {
  content: "\e041";
}
.icon-bike:before {
  content: "\e042";
}
.icon-wine:before {
  content: "\e043";
}
.icon-hotairballoon:before {
  content: "\e044";
}
.icon-globe:before {
  content: "\e045";
}
.icon-genius:before {
  content: "\e046";
}
.icon-map-pin:before {
  content: "\e047";
}
.icon-dial:before {
  content: "\e048";
}
.icon-chat:before {
  content: "\e049";
}
.icon-heart:before {
  content: "\e04a";
}
.icon-cloud:before {
  content: "\e04b";
}
.icon-upload:before {
  content: "\e04c";
}
.icon-download:before {
  content: "\e04d";
}
.icon-target:before {
  content: "\e04e";
}
.icon-hazardous:before {
  content: "\e04f";
}
.icon-piechart:before {
  content: "\e050";
}
.icon-speedometer:before {
  content: "\e051";
}
.icon-global:before {
  content: "\e052";
}
.icon-compass:before {
  content: "\e053";
}
.icon-lifesaver:before {
  content: "\e054";
}
.icon-clock:before {
  content: "\e055";
}
.icon-aperture:before {
  content: "\e056";
}
.icon-quote:before {
  content: "\e057";
}
.icon-scope:before {
  content: "\e058";
}
.icon-alarmclock:before {
  content: "\e059";
}
.icon-refresh:before {
  content: "\e05a";
}
.icon-happy:before {
  content: "\e05b";
}
.icon-sad:before {
  content: "\e05c";
}
.icon-facebook:before {
  content: "\e05d";
}
.icon-twitter:before {
  content: "\e05e";
}
.icon-googleplus:before {
  content: "\e05f";
}
.icon-rss:before {
  content: "\e060";
}
.icon-tumblr:before {
  content: "\e061";
}
.icon-linkedin:before {
  content: "\e062";
}
.icon-dribbble:before {
  content: "\e063";
}

/* ==========================================================================
   Services Section
   ========================================================================== */
.services-item {
  padding: 40px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.services-item:hover {
  background-color: #222;
  transition: all 0.2s ease-in-out;
}
.services-item .dots {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAEsCAYAAAB5fY51AAAACXBIWXMAACmGAAAphgEi7N+MAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAARqSURBVHja7N0xyt4wFERRK3h10X6zvUkTcO0BP9Cfc0CQxh5IcbGK5FtJLoAT/PJXAAgWgGABggUgWACCBQgWgGABCBYgWACCBSBYwI92l8+t67p+//vzn+u6pv4FtV27dv/n3STN2Xns8h127dq1++q4EgLHWOX/h+VT1q5du+O7y3/gB5zClRAQLADBAgQLQLAABAsQLADBAhAsQLAABAtAsADBAhAsAMECBAtAsAAECxAsAMECECxAsAAEC0CwAMEC+NxdPucntu3atTu/m6Q5O49dvsOuXbt2Xx1XQuAYK6m+Bn3K2rVrd3y3DRbAOFdCQLAABAsQLADBAhAsQLAABAtAsADBAhAsAMECBAtAsAAECxAsAMECECxAsAAEC0CwAMECECwAwQIEC+Bzd/mcn9i2a9fu/G6S5uw8dvkOu3bt2n11XAmBY6yk+hr0KWvXrt3x3TZYAONcCQHBAhAsQLAABAtAsADBAhAsAMECBAtAsAAECxAsAMECECxAsAAEC0CwAMECECwAwQIEC0CwAAQLECyAz93lc35i265du/O7SZqz89jlO+zatWv31XElBI6xkupr0KesXbt2x3fbYAGMcyUEBAtAsADBAhAsAMECBAtAsAAECxAsAMECECxAsAAEC0CwAMECECwAwQIEC0CwAAQLECwAwQIQLECwAD53l8/5iW27du3O7yZpzs5jl++wa9eu3VfHlRA4xkqqr0Gfsnbt2h3fbYMFMM6VEBAsAMECBAtAsAAECxAsAMECECxAsAAEC0CwAMECECwAwQIEC0CwAAQLECwAwQIQLECwAAQLQLAAwQL43F0+5ye27dq1O7+bpDk7j12+w65du3ZfHVdC4Bgrqb4GfcratWt3fLcNFsA4V0JAsAAECxAsAMECECxAsAAEC0CwAMECECwAwQIEC0CwAAQLECwAwQIQLECwAAQLQLAAwQIQLADBAgQL4HN3+Zyf2LZr1+78bpLm7Dx2+Q67du3afXVcCYFjrKT6GvQpa9eu3fHdNlgA41wJAcECECxAsAAEC0CwAMECECwAwQIEC0CwAAQLECwAwQIQLECwAAQLQLAAwQIQLADBAgQLQLAABAsQLIDP3eVzfmLbrl2787tJmrPz2OU77Nq1a/fVcSUEjrGS6mvQp6xdu3bHd9tgAYxzJQQEC0CwAMECECwAwQIEC0CwAAQLECwAwQIQLECwAAQLQLAAwQIQLADBAgQLQLAABAsQLADBAhAsQLAAPneXz/mJbbt27c7vJmnOzmOX77Br167dV8eVEDjGSqqvQZ+ydu3aHd9tgwUwzpUQECwAwQIEC0CwAAQLECwAwQIQLECwAAQLQLAAwQIQLADBAgQLQLAABAsQLADBAhAsQLAABAtAsADBAvjcXT7nJ7bt2rU7v5ukOTuPXb7Drl27dl8dV0LgGCupvgZ9ytq1a3d8tw0WwDhXQkCwAAQLECwAwQIQLECwAAQLQLAAwQIQLADBAn62vwAAAP//AwBSVNnYyjGLEgAAAABJRU5ErkJggg==);
  position: absolute;
  width: 200px;
  height: 200px;
  right: -50px;
  bottom: -50px;
  opacity: 0.5;
  visibility: hidden;
  opacity: 0;
  display: block;
  transition: all 0.2s ease-in-out;
  z-index: -1;
}
.services-item:hover .dots {
  visibility: visible;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}
.services-item i {
  color: #9cbc1b;
  font-size: 50px;
  display: block;
  margin-bottom: 20px;
}
.services-item h3 {
  font-size: 36px;
}
.services-item p {
  margin-bottom: 0;
}

/* ==========================================================================
   Portfolio Section
   ========================================================================== */
.portfolio-box {
  position: relative;
  overflow: hidden;
}
.portfolio-content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.portfolio-content:before {
  background-color: #222;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  bottom: -100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: -1;
}
.portfolio-box:hover .portfolio-content:before {
  bottom: 0;
  visibility: visible;
  opacity: 1;
}
.portfolio-content h3 {
  margin-bottom: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  transition: all 0.2s ease-in-out;
  font-size: 28px !important;
  cursor: pointer;
}
.portfolio-box:hover h3 {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition-delay: 0.2s;
  visibility: visible;
  opacity: 1;
}
.portfolio-content-a {
  font-family: "Teko", sans-serif;
  display: block;
  color: #fff;
  text-align: center;
}
.portfolio-content-a span {
  display: block;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

/* =======  Portfolio Filter Styles  ======= */
.portfolio-filter {
  display: block;
}
.portfolio-filter li {
  display: inline-block;
  position: relative;
  margin: 0 10px;
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  font-family: "Teko", sans-serif;
  font-weight: 400;
}
.portfolio-filter li a {
  display: inline-block;
}
.portfolio-filter li:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 0;
  width: 100%;
  height: 5px;
  background: #9cbc1b;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  border-radius: 2px;
}
.portfolio-filter li.active:before,
.portfolio-filter li:hover:before,
.portfolio-filter li:focus:before,
.portfolio-filter li:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.portfolio-filter li:hover,
.portfolio-filter li:focus,
.portfolio-filter li:active {
  cursor: pointer;
}

.ril-inner img {
  width: unset !important;
}
.ril__inner img {
  width: unset !important;
}
.ril-toolbar {
  top: 47px;
  background-color: rgba(0, 0, 0, 0.8);
}
.ril__caption {
  background-color: rgba(0, 0, 0, 0.8);
}

.ReactModal__Overlay {
  width: unset !important;
}

.ReactModal__Overlay--after-open {
  width: unset !important;
}

@media screen and (max-width: 480px) {
  .ReactModal__Overlay {
    margin-right: 40px;
  }
  .ReactModal__Overlay--after-open {
    margin-right: 40px;
  }
}

/* ==========================================================================
   Contact Section
   ========================================================================== */
.contact-form .form-group {
  margin-bottom: 30px;
}
.contact-form .form-group .form-control {
  background-color: #222;
  border: none;
  height: 60px;
  border-radius: 2px;
  color: #fff;
}
.contact-form .form-group .form-control:focus {
  box-shadow: none;
  outline: none;
}
.contact-form .form-group .form-control.message {
  height: 150px;
}
.contact-form .default-btn {
  border: none;
}
.contact-form .default-btn:focus {
  outline: none;
}
#form-messages {
  display: none;
  margin: 0;
}
#form-messages.form-status {
  display: block;
}
#form-messages .alert {
  margin: 0;
}

/* ==========================================================================
   Footer Section
   ========================================================================== */
footer {
  padding: 60px 0;
  border-top: 1px solid #333;
}
footer p {
  color: #999;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}
footer p a {
  color: #cba982;
}
.footer-social a {
  margin-left: 15px;
}

.dl-side-content {
  position: relative;
}
.content-lines-wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.content-lines-inner {
  position: absolute;
  width: 1140px;
  left: 50%;
  margin-left: -570px;
  top: 0;
  height: 100%;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
}
.content-lines {
  position: absolute;
  left: 33.666666%;
  right: 33.666666%;
  height: 100%;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
}

@media screen and (max-width: 1199px) {
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .dl-side-content > .logo img {
    max-width: inherit;
    max-height: 30px;
    width: auto;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .dl-side-content > .logo img {
    max-width: inherit;
    max-height: 30px;
    width: auto;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}

/* =======  Preloader Styles  ======= */
.loaded .site-preloader-wrap {
  opacity: 0;
  visibility: hidden;
}
.site-preloader-wrap {
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
}
.site-preloader-wrap .spinner {
  background-color: #9cbc1b;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
}
.spinner {
  width: 40px;
  height: 40px;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

