/* ==========================================================================
   Resume Section
   ========================================================================== */
.exp-list li {
  margin-bottom: 40px;
}
.exp-list li:last-child {
  margin-bottom: 0;
}
.exp-date {
  margin-bottom: 10px;
}
.exp-date i {
  background-color: #9cbc1b;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
}

/* =======  Progress Bar Styles  ======= */
.skill-items .skill-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}
.skill-items .skill-item:last-child {
  margin-bottom: 0;
}
.skill-items .skill-item .progress {
  height: 10px;
  background-color: #ddd;
  box-shadow: none;
  margin-bottom: 0;
  overflow: visible;
  z-index: -1;
}
.skill-items .skill-item .progress,
.skill-items .skill-item .progress .progress-bar {
  border-radius: 2px;
}
.skill-items .skill-item .progress .progress-bar {
  line-height: 5px;
  background-color: #9cbc1b;
  position: relative;
  overflow: visible;
}
.skill-items .skill-item .progress .progress-bar span {
  background-color: #9cbc1b;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-family: "Teko", sans-serif;
  position: absolute;
  top: -10px;
  right: -15px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  z-index: 1;
}
.skill-items .skill-item .progress .progress-bar span:before {
  background-color: rgba(156, 188, 27, 0.8);
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  left: 0;
  top: 0;
  line-height: 30px;
  border-radius: 50%;
  transform: scale(1.5);
  text-align: center;
  z-index: -1;
}
