/* ==========================================================================
   Hero Section
   ========================================================================== */
.hero-section {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 400px;
  position: relative;
  z-index: 1;
}
.hero-section .particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.hero-content{
  z-index: 99;
}
.hero-content h1 {
  text-transform: uppercase;
}
.hero-content h2 {
  font-size: 24px;
}
.hero-content h4 span {
  color: #9cbc1b;
}
.hero-social {
  position: absolute;
  width: auto;
  height: auto;
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}
.hero-social li {
  line-height: 35px;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 991px) {
  .text-center {
    padding-top: 50 px;
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .hero-social {
    position: inherit;
    left: inherit;
    top: inherit;
    transform: translateY(0);
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }
  .hero-social li {
    display: inline-block;
    margin: 0 10px;
  }
}
