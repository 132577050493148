/* ==========================================================================
   Contact Section
   ========================================================================== */
.contact-form .form-group {
  margin-bottom: 30px;
}
.contact-form .form-group .form-control {
  background-color: #222;
  border: none;
  height: 60px;
  border-radius: 2px;
  color: #fff;
}
.contact-form .form-group .form-control:focus {
  box-shadow: none;
  outline: none;
}
.contact-form .form-group .form-control.message {
  height: 150px;
}
.contact-form .default-btn {
  border: none;
}
.contact-form .default-btn:focus {
  outline: none;
}
#form-messages {
  display: none;
  margin: 0;
}
#form-messages.form-status {
  display: block;
}
#form-messages .alert {
  margin: 0;
}
