.dl-side-content {
  position: relative;
}
.content-lines-wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.content-lines-inner {
  position: absolute;
  width: 1140px;
  left: 50%;
  margin-left: -570px;
  top: 0;
  height: 100%;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
}
.content-lines {
  position: absolute;
  left: 33.666666%;
  right: 33.666666%;
  height: 100%;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
}

@media screen and (max-width: 1199px) {
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .dl-side-content > .logo img {
    max-width: inherit;
    max-height: 30px;
    width: auto;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .dl-side-content > .logo img {
    max-width: inherit;
    max-height: 30px;
    width: auto;
  }
  .dl-side-content > .logo h1 {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 30px;
  }
  .dl-side-content > .logo h1 span {
    display: none;
  }
}
