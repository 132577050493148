/* ==========================================================================
   Services Section
   ========================================================================== */
.services-item {
  padding: 40px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.services-item:hover {
  background-color: #222;
  transition: all 0.2s ease-in-out;
}
.services-item .dots {
  background-image: url(../../img/doted-pattern.png);
  position: absolute;
  width: 200px;
  height: 200px;
  right: -50px;
  bottom: -50px;
  opacity: 0.5;
  visibility: hidden;
  opacity: 0;
  display: block;
  transition: all 0.2s ease-in-out;
  z-index: -1;
}
.services-item:hover .dots {
  visibility: visible;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}
.services-item i {
  color: #9cbc1b;
  font-size: 50px;
  display: block;
  margin-bottom: 20px;
}
.services-item h3 {
  font-size: 36px;
}
.services-item p {
  margin-bottom: 0;
}
