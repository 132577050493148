/* Bootstrap Menu Custom */

.dl-header-container {
  position: relative;
  text-align: center;
}

.dl-burger-menu {
  position: absolute;
  top: 35px;
  right: 14px;
  width: 20px;
  height: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  -webkit-transition: transform 330ms ease-out;
  -moz-transition: transform 330ms ease-out;
  -o-transition: transform 330ms ease-out;
  transition: transform 330ms ease-out;
}

.dl-burger-menu.dl-menu-open {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.dl-line-menu {
  background-color: #292929;
  border-radius: 2px;
  width: 100%;
  height: 2px;
}

.dl-line-menu.dl-line-half {
  width: 50%;
}

.dl-line-menu.dl-first-line {
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: right;
}

.dl-menu-open .dl-line-menu.dl-first-line {
  -webkit-transform: rotate(-90deg) translateX(3px);
  -moz-transform: rotate(-90deg) translateX(3px);
  -o-transform: rotate(-90deg) translateX(3px);
  transform: rotate(-90deg) translateX(3px);
}

.dl-line-menu.dl-last-line {
  align-self: flex-end;
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: left;
}

.dl-menu-open .dl-line-menu.dl-last-line {
  -webkit-transform: rotate(-90deg) translateX(-3px);
  -moz-transform: rotate(-90deg) translateX(-3px);
  -o-transform: rotate(-90deg) translateX(-3px);
  transform: rotate(-90deg) translateX(-3px);
}

/* Menu overlay */
.dl-header-overlay .dl-burger-menu,
.dl-fixed-sidebar + .dl-burger-menu {
  display: block;
  position: fixed;
  flex-direction: inherit;
  justify-content: inherit;
  width: 50px;
  height: 47px;
  top: 30px;
  right: 15px;
  background: #9cbc1b;
  padding: 15px;
  z-index: 99999;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.dl-header-overlay .dl-burger-menu:hover {
  -webkit-box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.4);
}

.dl-header-overlay .dl-burger-menu > span,
.dl-fixed-sidebar + .dl-burger-menu > span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 17px;
  -webkit-transition: transform 330ms ease-out;
  -moz-transition: transform 330ms ease-out;
  -o-transition: transform 330ms ease-out;
  transition: transform 330ms ease-out;
}

.dl-header-overlay .dl-line-menu,
.dl-fixed-sidebar + .dl-burger-menu .dl-line-menu {
  background-color: #fff;
}

.dl-header-overlay .dl-burger-menu.dl-menu-open,
.dl-fixed-sidebar + .dl-burger-menu.dl-menu-open {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}

.dl-header-overlay .dl-menu-open > span,
.dl-fixed-sidebar + .dl-menu-open > span {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@-webkit-keyframes menuFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes menuFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Fixed sidebar menu */

.body-fixed-sidebar {
  overflow-x: hidden;
}

.dl-fixed-sidebar {
  position: fixed;
  top: 0;
  width: 300px;
  height: 100%;
  z-index: 9999999;
  overflow-y: auto;
  background-color: #292929;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
}

.dl-sidebar-left {
  left: 0;
  /*    border-right: 1px solid #292929;*/
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.dl-sidebar-left.dl-fixed-sidebar-open {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}

.dl-fixed-sidebar .dl-header-container {
  /*
    display: flex;
    flex-wrap: wrap;
*/
  height: 100%;
  padding: 0 30px;
}

.dl-fixed-sidebar + .dl-burger-menu {
  z-index: 9999999;
}

.dl-sidebar-left + .dl-burger-menu {
  top: 0;
  right: inherit;
  left: 0;
}

.dl-fixedsidebar-bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.dl-side-content {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.dl-fixed-sidebar .logo {
  display: none;
  text-align: center;
  padding: 35px 0 35px 0px;
}
.dl-side-content > .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 47px;
  padding: 0 50px;
  z-index: 99999;
  text-align: center;
  background-color: #292929;
  -webkit-box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.15);
}
.dl-side-content > .logo img {
  max-width: inherit;
  max-height: 36px;
  width: auto;
}
.dl-fixed-sidebar .logo h1 {
  font-weight: 400;
  font-size: 75px;
  color: #9cbc1b;
}
.dl-fixed-sidebar .logo a span {
  display: block;
  margin-top: 0px;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Teko", sans-serif;
  color: #999;
  font-weight: 400;
  letter-spacing: 10px;
}
.dl-footer-logo {
  padding: 0 0 30px 0;
}
.dl-footer-logo img {
  max-width: inherit;
  max-height: 55px;
  width: auto;
}
.dl-fixed-sidebar-open ~ .dl-fixedsidebar-bg-overlay {
  visibility: visible;
  opacity: 1;
}

.dl-fixed-menu-wrap {
  width: 100%;
}

/* Navigation menu */

.dl-menu-fixed {
  padding-top: 90px;
  text-align: left;
}

.dl-menu-fixed ul li {
  position: relative;
}

.dl-menu-fixed ul li a {
  font-family: "Teko", sans-serif;
  display: block;
  padding-right: 17px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.dl-menu-fixed > ul > li > a.active,
.dl-menu-fixed ul li:hover > a {
  color: #9cbc1b !important;
}

.dl-menu-fixed > ul {
  margin-bottom: 40px;
}

.dl-menu-fixed > ul > li {
  border-bottom: 1px solid #323232;
  margin: 20px 0 20px 0;
  padding: 0 0 10px 0;
}

.dl-menu-fixed > ul > li:last-child {
  margin-bottom: 0;
}

.dl-menu-fixed > ul > li > a {
  font-family: "Teko", sans-serif;
  font-weight: 400;
  color: #999;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.dl-menu-fixed > ul ul {
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.dl-menu-fixed > ul ul.submenu-collapse {
  opacity: 1;
}

.dl-menu-fixed > ul ul li {
  margin-bottom: 6px;
}

.dl-menu-fixed > ul ul li:last-child {
  margin-bottom: 0;
}

.dl-menu-fixed > ul ul a {
  position: relative;
  padding-left: 18px;
  font-size: 15px;
  font-style: italic;
  color: #818181;
}

.dl-menu-fixed > ul ul a::before {
  position: absolute;
  content: "";
  top: 3px;
  left: 4px;
  width: 1px;
  height: 12px;
  background-color: #d1d1d1;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}

.dl-menu-fixed > ul ul a:hover {
  padding-left: 24px;
}

.dl-menu-fixed > ul > li > ul {
  padding: 27px 0 15px;
}

.dl-menu-fixed > ul > li > ul ul {
  padding-top: 6px;
}

.dl-menu-fixed > ul > li > ul > li ul {
  padding-left: 18px;
}

.dl-menu-fixed .overlay-dropdown-plus {
  top: -1px;
  right: -7px;
  opacity: 1;
}

.dl-menu-fixed > ul ul .overlay-dropdown-plus {
  top: -3px;
}

.dl-menu-fixed.submenu-no-separate > ul ul a::before {
  display: none !important;
}

.dl-menu-fixed.submenu-no-separate > ul ul a,
.dl-menu-fixed.submenu-no-separate > ul > li > ul > li ul {
  padding-left: 12px;
}

.dl-menu-fixed.submenu-no-separate > ul ul a:hover {
  padding-left: 18px;
}

/* Menu icons */

.dl-menu-fixed .dl-menu-icon {
  margin-right: 5px;
}

/* Media queries for responsive design */

@media (min-width: 768px) {
  .dl-header .logo {
    float: left;
    padding: 30px 40px 30px 0;
  }

  .dl-menu {
    clear: both;
  }

  .dl-header-shadow-wrapper:after {
    -webkit-box-shadow: 0px 10px 40px -5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 40px -5px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 10px 40px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 40px -5px rgba(0, 0, 0, 0.1);
  }

  .dl-header-overlay .logo {
    left: 30px;
  }

  .dl-header-overlay .dl-burger-menu {
    right: 30px;
  }
}

@media (min-width: 1200px) {
  /* Viewport Breakpoint */
  .dl-header-container {
    height: 90px;
  }

  .dl-header-overlay .dl-header-container {
    height: auto;
  }

  .dl-header .logo {
    position: relative;
    padding: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .dl-header .logo img {
    max-width: inherit;
    max-height: 90px;
    width: auto;
  }

  .dl-burger-menu {
    display: none;
  }

  /* Header sticky */
  .dl-header.sticky {
    position: fixed;
    width: 100%;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: stickyTransition;
    animation-name: stickyTransition;
  }

  .dl-header.sticky .dl-header-container {
    height: 70px;
  }

  /* Center menu */
  .dl-header.center-menu-1 .dl-header-container > .logo {
    display: none;
  }

  .dl-header.center-menu-2 .dl-header-container {
    height: auto;
  }

  /* Menu overlay */
  .dl-header-overlay .dl-burger-menu {
    top: 40px;
    right: 40px;
  }

  /* Fixed sidebar menu */
  .dl-fixed-sidebar {
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
  }

  .dl-side-content > .logo,
  .dl-fixed-sidebar + .dl-burger-menu,
  .dl-fixedsidebar-bg-overlay {
    display: none;
  }

  .dl-fixed-sidebar .logo {
    display: block;
  }

  .dl-fixed-sidebar .logo img {
    max-width: inherit;
    max-height: 50px;
    width: auto;
    margin: 0 20px;
  }

  .dl-side-content {
    width: calc(100% - 300px);
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
    -webkit-transform: translateX(0%) !important;
    -moz-transform: translateX(0%) !important;
    -o-transform: translateX(0%) !important;
    transform: translateX(0%) !important;
  }

  .dl-sidebar-left ~ .dl-fixedsidebar-bg-overlay + .dl-side-content {
    margin-left: auto;
  }

  .dl-sidebar-left,
  .dl-sidebar-right {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    z-index: auto;
  }

  .dl-menu-fixed {
    padding-top: 0;
    padding-bottom: 30px;
  }
}
