/* ==========================================================================
   Footer Section
   ========================================================================== */
footer {
  padding: 60px 0;
  border-top: 1px solid #333;
}
footer p {
  color: #999;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}
footer p a {
  color: #cba982;
}
.footer-social a {
  margin-left: 15px;
}
