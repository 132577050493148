/* =======  Preloader Styles  ======= */
.loaded .site-preloader-wrap {
  opacity: 0;
  visibility: hidden;
}
.site-preloader-wrap {
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
}
.site-preloader-wrap .spinner {
  background-color: #9cbc1b;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
}
.spinner {
  width: 40px;
  height: 40px;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}
