/* ==========================================================================
   Portfolio Section
   ========================================================================== */
.portfolio-box {
  position: relative;
  overflow: hidden;
}
.portfolio-content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.portfolio-content:before {
  background-color: #222;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  bottom: -100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: -1;
}
.portfolio-box:hover .portfolio-content:before {
  bottom: 0;
  visibility: visible;
  opacity: 1;
}
.portfolio-content h3 {
  margin-bottom: 0;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.2s ease-in-out;
  font-size: 28px !important;
  cursor: pointer;
}
.portfolio-box:hover h3 {
  transform: translateY(0);
  transition-delay: 0.2s;
  visibility: visible;
  opacity: 1;
}
.portfolio-content-a {
  font-family: "Teko", sans-serif;
  display: block;
  color: #fff;
  text-align: center;
}
.portfolio-content-a span {
  display: block;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

/* =======  Portfolio Filter Styles  ======= */
.portfolio-filter {
  display: block;
}
.portfolio-filter li {
  display: inline-block;
  position: relative;
  margin: 0 10px;
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  font-family: "Teko", sans-serif;
  font-weight: 400;
}
.portfolio-filter li a {
  display: inline-block;
}
.portfolio-filter li:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 0;
  width: 100%;
  height: 5px;
  background: #9cbc1b;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  border-radius: 2px;
}
.portfolio-filter li.active:before,
.portfolio-filter li:hover:before,
.portfolio-filter li:focus:before,
.portfolio-filter li:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.portfolio-filter li:hover,
.portfolio-filter li:focus,
.portfolio-filter li:active {
  cursor: pointer;
}

.ril-inner img {
  width: unset !important;
}
.ril__inner img {
  width: unset !important;
}
.ril-toolbar {
  top: 47px;
  background-color: rgba(0, 0, 0, 0.8);
}
.ril__caption {
  background-color: rgba(0, 0, 0, 0.8);
}

.ReactModal__Overlay {
  width: unset !important;
}

.ReactModal__Overlay--after-open {
  width: unset !important;
}

@media screen and (max-width: 480px) {
  .ReactModal__Overlay {
    margin-right: 40px;
  }
  .ReactModal__Overlay--after-open {
    margin-right: 40px;
  }
}
